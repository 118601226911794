<template>
    <div id="headerBofy">
        <div class="schoolName">
            上海市杨浦区新跃幼稚园 I 规划与管理平台
        </div>
        <div class="why">
            <img src="@/assets/why.png" alt="">
        </div>

        <el-dropdown>
            <div class="userInfo">
                <div class="avater">
                    <img src="@/assets/avater.png" alt="">
                </div>
                <div class="userName">
                    {{ userInfo.uRealName }}
                </div>
            </div>

            <el-dropdown-menu slot="dropdown">
                <div class="row">
                    <span class="label">账号：</span>
                    {{ userInfo.uLoginName }}
                </div>
                <div class="row">
                    <span class="label">角色：</span>
                    <span class="role">{{ userInfo.uLoginName }}</span>
                </div>
                <div class="row">
                    <span class="label">部门：</span>园长办公室
                </div>
                <div class="row">
                    <span class="label">职位：</span>园长助理
                </div>

                <!-- <div class="btn1" @click="updatePwdHandler">修改密码</div> -->
                <div class="btn2" @click="LogOutHandler">退出登录</div>
            </el-dropdown-menu>
        </el-dropdown>
        <UpdatePwd ref="UpdatePwd"></UpdatePwd>
    </div>
</template>
<script>
import UpdatePwd from "@/components/UpdatePwd.vue"
import { LogOut, getUserInfo } from "@/utils/auth"
export default {
    components: {
        UpdatePwd
    },
    data() {
        return {
            userInfo: {}
        }
    },
    created() {
        this.userInfo = getUserInfo()
    },
    methods: {
        updatePwdHandler() {
            this.$refs.UpdatePwd.show()
        },
        LogOutHandler() {
            LogOut()
        }
    }
}
</script>
<style scoped lang="less">
#headerBofy {
    width: 100%;
    background-color: #ffffff;
    height: 58px;
    display: flex;
    align-items: center;
}

.userInfo {
    display: flex;
    align-items: center;
}

.schoolName {
    font-size: 16px;
    color: #333333;
    text-align: left;
    line-height: 58px;
    flex: 1;
    padding-left: 30px;
}

.btn1 {
    font-weight: 400;
    font-size: 16px;
    color: #238296;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    margin-top: 40px;
}

.btn2 {
    font-weight: 400;
    font-size: 16px;
    color: #FC5560;
    line-height: 22px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.row {
    width: 300px;
    font-size: 18px;
    color: #8C8C8C;
    line-height: 25px;
    text-align: left;
    padding-left: 24px;
    margin-top: 16px;

    .label {
        font-size: 18px;
        color: #8C8C8C;
    }

    .role {
        font-size: 18px;
        color: #238296;

    }
}

.why {
    padding: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}

.avater {
    cursor: pointer;
    width: 39px;
    height: 39px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 14px;

    img {
        width: 39px;
        height: 39px;
        object-fit: cover;
    }

    .role {}
}

.userName {
    cursor: pointer;
    line-height: 58px;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    padding-right: 22px;
    padding-left: 10px;
}
</style>