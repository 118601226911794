<template>
    <div id="layoutBody">

        <layotuMenu>

        </layotuMenu>
        <div id="rightBody">
            <layotuHeader></layotuHeader>
            <div id="mainBody">
                <router-view :key="$route.path"></router-view>

            </div>
        </div>
    </div>
</template>
<script>
import layotuHeader from '@/layout/Header.vue';
import layotuMenu from '@/layout/Menu.vue';
import { getRouterList } from "@/utils/auth"
export default {
    components: {
        layotuMenu,
        layotuHeader
    },
    methods: {

    },
    created() {
        if (this.$route.fullPath == '/layout') {
            let routes = getRouterList()
            this.$router.replace("/layout/" + routes[0].PathWS)
        }

    }
}
</script>
<style scoped lang="less">
#layoutBody {
    display: flex;
}

#rightBody {
    width: calc(100vw - 130px);
}

#mainBody {
    height: calc(100vh - 58px);
    box-sizing: border-box;
    background-color: #F8F6F6;
    overflow-y: scroll;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    min-width: 1300px;
}


#mainBody ::-webkit-scrollbar {
    display: none;
}
</style>