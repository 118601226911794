/*
 * @Date: 2024-05-25 17:43:40
 * @LastEditors: 王帅 1677191300@qq.com
 * @LastEditTime: 2024-05-25 18:02:03
 * @FilePath: \new-leap-pc\src\utils\index.js
 * @Description: 
 */

/**
 * 判断是否存在权限
 * @param {*} PermissionAllList 所有权限
 * @param {*} PermissionList 需要判断的权限
 * @returns 
 */
export function hasPermission(PermissionAllList, PermissionList) {
    if (typeof PermissionAllList === "string") {
        if (PermissionAllList === '*') {
            return true
        }
    } else if (isArray(PermissionAllList)) {
        if (PermissionAllList.includes('*')) {
            return true
        }
    }

    let hasPermission = false;
    if (typeof PermissionList === "string") {
        if (PermissionList === '*') {
            return true
        }
        hasPermission = PermissionAllList.includes(PermissionList);
    } else if (isArray(PermissionList)) {
        if (PermissionList.includes('*')) {
            return true
        }
        hasPermission = PermissionAllList.some((item) => PermissionList.includes(item));
    }
    return hasPermission
}

/**
 * 是否是数组
 * @param {*} arr 
 */
export function isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]'
}



/**
 * 获取一个随机字符串
 * @returns 
 */
export function getRandomString() {
    return Math.random().toString(36).substr(2);
}

export const getButtonList = (routePath, routers) => {
    if (routers === undefined) {
        routers = JSON.parse(sessionStorage.getItem('routerList')) || []
    }
    let buttonList = [];
    routers.forEach(element => {
        if (routePath && element.path) {
            let path = routePath.toLowerCase();
            if (element.path && element.path.toLowerCase() === path) {
                buttonList = element.children;
                return;
            } else if (element.children) {
                getButtonList(path, element.children);
            }
        }
    });
    return buttonList;
};

/**
 * 去除字符串前面的空格
 * @param {*} str 
 * @returns 
 */
export function trimSrtFront(str) {
    return str.replace(/^\s+/, '')
}

/**
 * 数字转中文
 * @param {*} num 
 * @returns 
 */
export function numberToChinese(num) {
    const chineseNumArr = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chineseUnitArr = ['', '十', '百', '千', '万', '亿'];

    let result = '';
    let unitIndex = 0;

    if (num === 0) {
        result = chineseNumArr[0];
    }

    while (num > 0) {
        let temp = num % 10;
        if (temp === 0) {
            if (result[0] !== chineseNumArr[0]) {
                result = chineseNumArr[0] + result;
            }
        } else {
            result = chineseNumArr[temp] + chineseUnitArr[unitIndex] + result;
        }

        unitIndex++;
        num = Math.floor(num / 10);
    }

    return result;
}

/**
 * 节流
 * @param {*} callback 回调函数
 * @param {*} time 时间
 * @param {*} immediately 是否立即执行
 * @returns 
 */
//throttle.js
/**
 * @param {*} fn 要执行的函数
 * @param {*} interval 时间间隔
 * @returns 
 */
export function throttle(fn, interval) {
    // 1.记录上一次的开始时间
    let lastTime = 0

    // 2.事件触发时, 真正执行的函数
    const _throttle = function (...args) {

        // 2.1.获取当前事件触发时的时间
        const nowTime = new Date().getTime()

        // 2.2.使用当前触发的时间和之前的时间间隔以及上一次开始的时间, 计算出还剩余多长时间需要去触发函数
        const remainTime = interval - (nowTime - lastTime)
        //第一次会执行，原因是nowTime刚开始是一个很大的数字，结果为负数
        //若最后一次没能满足条件，不会执行
        if (remainTime <= 0) {
            // 2.3.真正触发函数
            fn.apply(this, args)
            // 2.4.保留上次触发的时间
            lastTime = nowTime
        }
    }

    return _throttle
}

// 防抖 
export function debounce(callback, time) {
    var timer;
    //使用闭包,可以避免污染全局变量
    return function () {
        clearTimeout(timer) //清除计时器
        //重新开始计时
        var args = arguments
        timer = setTimeout(() => {
            callback.apply(null, args)
        }, time);
    }
}


/**
 * 格式化时间
 * @param {*} date 
 * @returns 
 */
export function FormatDateTime(date) {
    function addZore(num) {
        if (num >= 0 && num <= 9) {
            return '0' + num
        } else {
            return '' + num
        }
    }

    let dateObj = date == undefined ? new Date() : new Date(date)
    let week = dateObj.getDay()
    if (week === 0) {
        week = 7
    }
    let years = dateObj.getFullYear()
    let month = dateObj.getMonth() + 1
    let day = dateObj.getDate()
    return {
        years: years,
        month: month,
        day: day,
        week: week,
        dateNum: `${addZore(years)}${addZore(month)}${addZore(day)}`,
        date_: `${addZore(years)}-${addZore(month)}-${addZore(day)}`,
        date: `${addZore(years)}/${addZore(month)}/${addZore(day)}`,
        dateText: `${addZore(years)}年${addZore(month)}月${addZore(day)}日`,
    }
}