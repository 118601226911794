
let userInfoKey = 'userInfo'
let tokenKey = 'token'
let permissionListKey = 'permissionList'
let expiresInKey = 'expires_in'
let routerList = 'routerList'
import router from '@/router/index'
import { MessageBox } from 'element-ui';
/**
 * 设置用户鉴权信息
 * @param {*} userInfo 
 * @param {*} token 
 */
export function setUserAuth(userInfo, token, permissionList, expires_in) {
    if (userInfo) {
        sessionStorage.setItem(userInfoKey, JSON.stringify(userInfo))
    }
    if (token || expires_in) {
        setToken(token, expires_in)
    }
    if (permissionList) {
        sessionStorage.setItem(permissionListKey, JSON.stringify(permissionList))
    }
}

/**
 * 设置路由菜单
 * @param {*} list 
 */
export function setRouterList(list) {
    if (list) {
        sessionStorage.setItem(routerList, JSON.stringify(list))
    }
    addRoutes()
}

/**
 * 根据代码路径跳转动态路由
 * @param {*} CodeAddress 
 */
export function jumpPathByCodeAddress(CodeAddress, query = {}) {
    let queryStr = ""
    let queryArr = []
    for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
            const element = query[key];
            queryArr.push(key + "=" + element)
        }
    }
    queryStr = queryArr.join("&")
    let routers = getRouterList()
    let routerObj = routers.find(item => {
        return CodeAddress == item.path
    })
    if (routerObj) {
        if (queryStr) {
            router.push("/layout/" + routerObj.PathWS + "?" + queryStr)
        } else {
            router.push("/layout/" + routerObj.PathWS)
        }
    }
}

/**
 * 设置路由
 */
export function addRoutes() {
    let routers = getRouterList()
    if (routers) {
        routers.forEach(element => {
            if (element.PathWS && element.path) {
                router.addRoute("layout", {
                    path: element.PathWS,
                    name: element.PathWS,
                    component: () => import("@/views" + element.path),
                    meta: {
                        id: element.id
                    }
                })
            }
        });
    }
}
/**
 * 获取路由列表
 * @returns 
 */
export function getRouterList() {
    let routerArr = JSON.parse(sessionStorage.getItem(routerList)) || []
    return routerArr.filter(item => {
        return item.PathWS && item.path && item.IsHide == false
    })
}

/**
 * 设置token
 * @param {*} token 
 */
export function setToken(token, expires_in) {
    if (token) {
        sessionStorage.setItem(tokenKey, token)
    }
    if (expires_in) {
        sessionStorage.setItem(expiresInKey, expires_in)
    }

}

/**
 * 删除用户鉴权信息
 */
export function delUserAuth() {
    sessionStorage.removeItem(userInfoKey)
    sessionStorage.removeItem(tokenKey)
    sessionStorage.removeItem(permissionListKey)
}

/**
 * 获取用户信息
 * @returns 
 */
export function getUserInfo() {
    return JSON.parse(sessionStorage.getItem(userInfoKey))
}

/**
 * 获取token
 * @returns 
 */
export function getToken() {
    return sessionStorage.getItem(tokenKey)
}

/**
 * 获取权限列表
 * @returns 
 */
export function getPermissionList() {
    // return sessionStorage.getItem(permissionListKey) || []
    let routerArr = JSON.parse(sessionStorage.getItem(routerList)) || []
    let btnGroup = routerArr.find(item => {
        return item.path == 'btnGroup'
    })
    if (btnGroup && btnGroup.children) {
        let btnAll = btnGroup.children.map(item => item.PathWS)
        return btnAll
    } else {
        return []
    }
}

/**
 * 退出登录
 * @returns 
 */
export function LogOut(msg) {
    MessageBox.confirm(msg || '是否确认退出登录?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        sessionStorage.clear()
        router.replace("/")
    }).catch(() => { })

}

/**
 * 强制退出登录
 * @param {*} msg 
 */
export function forceLogOut(msg) {
    MessageBox.alert(msg || '登录已过期，请重新登录', '退出登录', {
        confirmButtonText: '确定',
        type: 'warning'
    }).then(() => {
        sessionStorage.clear()
        router.replace("/")
    }).catch(() => { })

}


/**
 * 是否是校长 
 */
export function isPrincipal() {
    let user = getUserInfo()
    if (user.IsAdmin == 1 && uRemark == 'admin') {
        return true
    } else {
        return false
    }
}