/*
 * @Date: 2024-05-25 17:43:40
 * @LastEditors: 王帅 1677191300@qq.com
 * @LastEditTime: 2024-05-26 09:35:06
 * @FilePath: \new-leap-pc\src\main.js
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import directives from "@/directives/index"
import { addRoutes } from './utils/auth'; "@/utils/auth"
addRoutes()

Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
Vue.use(directives);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
