/*
 * @Date: 2024-05-25 17:43:40
 * @LastEditors: 王帅 1677191300@qq.com
 * @LastEditTime: 2024-05-27 11:17:44
 * @FilePath: \new-leap-pc\src\router\index.js
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout/index.vue'
import menuList from './menu'
import { filterRouter } from "./handleRouter"
Vue.use(VueRouter)

const routes = [
  {
    path: '/layout',
    name: 'layout',
    component: layout,
    children: [{
      path: 'ThreeYearPlan',
      name: 'ThreeYearPlan',
      component: () => import('../views/ThreeYearPlan/index.vue')
    }, {
      path: 'TaskDetails',
      name: 'TaskDetails',
      component: () => import('../views/TaskDetails.vue')
    }]
  },
  {
    path: '/Pdf',
    name: 'Pdf',
    component: () => import('../views/Pdf/pdf.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },

  // 404 页面
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    hidden: true,
    meta: { title: "404" },
  },
  // 当什么都没有匹配到的时候，重定向页面到 404 页面
  // { path: "/:pathMatch(.*)", redirect: "/404", name: "notMatch", hidden: true },
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
