import { getPermissionList } from "@/utils/auth"
import { hasPermission } from "@/utils/index"
const permission = {
  inserted(el, binding) {
    const permissionList = getPermissionList()
    const value = binding.value;
    let flag = hasPermission(permissionList, value);
    if (!flag) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
export default permission;
