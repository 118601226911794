/*
 * @Date: 2024-05-25 17:43:40
 * @LastEditors: 王帅 1677191300@qq.com
 * @LastEditTime: 2024-05-26 10:59:10
 * @FilePath: \new-leap-pc\src\router\menu.js
 * @Description: 
 */
export default [
    {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/Home/index.vue'),

        mate: {
            id: 1,
            title: "首页",
            permission: ['*'],
            icon: require("@/assets/mune/article2.png"),
            activeIcon: require("@/assets/mune/article1.png"),
        }
    }, {
        path: '/Message',
        name: 'Message',
        component: () => import('@/views/Message/index.vue'),
        mate: {
            id: 9,
            title: "消息",
            permission: ['*'],
            icon: require("@/assets/mune/article2.png"),
            activeIcon: require("@/assets/mune/article1.png"),
        }
    },
    {
        path: '/Auit',
        name: 'Auit',
        component: () => import('@/views/Auit/index.vue'),

        mate: {
            id: 2,
            title: "审核&任务",
            permission: ['*'],
            icon: require("@/assets/mune/archives2.png"),
            activeIcon: require("@/assets/mune/archives1.png"),
        }
    },
    {
        path: '/Task',
        name: 'Task',
        component: () => import('@/views/Task/index.vue'),

        mate: {
            id: 3,
            title: "我的任务",
            permission: ['*'],
            icon: require("@/assets/mune/rule2.png"),
            activeIcon: require("@/assets/mune/rule1.png"),
        }
    },
    {
        path: '/ThreeYearPlan',
        name: 'ThreeYearPlan',
        component: () => import('@/views/ThreeYearPlan/index.vue'),
        mate: {
            id: 4,
            title: "园所三年规划",
            permission: ['*'],
            icon: require("@/assets/mune/notepad2.png"),
            activeIcon: require("@/assets/mune/notepad1.png"),
        }
    },
    {
        path: '/ThreeYearPlan1',
        name: 'ThreeYearPlan1',
        component: () => import('@/views/ThreeYearPlan/index.vue'),
        mate: {
            id: 5,
            title: "园所学期规划",
            permission: ['*'],
            icon: require("@/assets/mune/firm2.png"),
            activeIcon: require("@/assets/mune/firm1.png"),
        }
    }, {
        path: '/ThreeYearPlan2',
        name: 'ThreeYearPlan2',
        component: () => import('@/views/ThreeYearPlan/index.vue'),
        mate: {
            id: 6,
            title: "部门三年规划",
            permission: ['*'],
            icon: require("@/assets/mune/edit2.png"),
            activeIcon: require("@/assets/mune/edit1.png"),
        }
    },
    {
        path: '/ThreeYearPlan3',
        name: 'ThreeYearPlan3',
        component: () => import('@/views/ThreeYearPlan/index.vue'),
        mate: {
            id: 8,
            title: "部门学期规划",
            permission: ['*'],
            icon: require("@/assets/mune/edit2.png"),
            activeIcon: require("@/assets/mune/edit1.png"),
        }
    },
    {
        path: '/DirectoryConfiguration',
        name: 'DirectoryConfiguration',
        component: () => import('@/views/DirectoryConfiguration/index.vue'),
        mate: {
            id: 7,
            title: "系统配置",
            permission: ['*'],
            icon: require("@/assets/mune/setting2.png"),
            activeIcon: require("@/assets/mune/setting1.png"),
        }
    },
    {
        path: '/manage',
        name: 'manage',
        component: () => import('@/views/manage/index.vue'),
        mate: {
            id: 10,
            title: "工作台",
            permission: ['*'],
            icon: require("@/assets/mune/setting2.png"),
            activeIcon: require("@/assets/mune/setting1.png"),
        }
    }

]