<template>
    <div id="menuBody" class=" theme1">
        <img src="@/assets/logo2.png" class="logo" alt="" srcset="">

        <!-- <el-popover placement="right" width="200" trigger="hover">
            <div class="menuItem" slot="reference" @click="jumpPathSetter()">
                <img class="menuIcon" v-if="'-10' == menuAntiveId" src="@/assets/mune/setting1.png" alt="">
                <img class="menuIcon" v-else src="@/assets/mune/setting2.png" alt="">
                <div :class="['menuName', 'textEllipsis', '-10' == menuAntiveId ? 'menuNameActive' : '']">
                    工作台
                </div>
            </div>

            <div class="SecondLevelMenu">
                <div class="borderB">部门权限管理</div>
                <div class="borderB">用户角色管理</div>
                <div class="borderB">菜单管理</div>
                <div>权限分配</div>
            </div>
        </el-popover> -->
        <div class="menuItem" v-for="item in menuList" :key="item.id" @click="jumpPath(item)">
            <img class="menuIcon" v-if="item.id == menuAntiveId" :src="getLocalResource('active/' + item.iconCls)" alt="">
            <img class="menuIcon" v-else :src="getLocalResource('normal/' + item.iconCls)" alt="">
            <div :class="['menuName', 'textEllipsis', item.id == menuAntiveId ? 'menuNameActive' : '']">
                {{
                    item.name
                }}
            </div>
        </div>
    </div>
</template>
<script>
import menuList from "@/router/menu"
import { filterRouterPermission } from "@/router/handleRouter"
import { getRouterList } from "@/utils/auth"
export default {
    data() {
        return {
            menuList: getRouterList(),
            menuAntiveId: undefined,
        }
    },
    created() {
        if (this.$route.meta && this.$route.meta.id) {
            this.menuAntiveId = this.$route.meta.id
        } else {
            this.menuAntiveId = undefined
        }
    },
    watch: {
        // $route: {
        //     deep: true,
        //     handler() {

        //         if (this.$route.meta && this.$route.meta.id) {
        //             this.menuAntiveId = this.$route.meta.id
        //         } else {
        //             this.menuAntiveId = undefined
        //         }
        //     }
        // },
    },
    methods: {
        getLocalResource(path) {
            try {
                return require(`../assets/mune/` + path)
            } catch (error) {
                console.log(error)
            }
        },
        jumpPathSetter() {
            this.menuAntiveId = "-10"
        },
        jumpPath(item) {
            if (!item.PathWS) {
                this.$router.push("/404")
                return
            }
            if (item.PathWS && item.PathWS != this.$route.fullPath) {
                this.$router.push("/layout/" + item.PathWS)
                this.menuAntiveId = item.id
            }


        },
    }
}
</script>
<style scoped lang="less">
#menuBody ::-webkit-scrollbar {
    display: none;
}

.SecondLevelMenu {
    div {
        line-height: 50px;
        height: 50px;
        padding-left: 20px;
        cursor: pointer;
    }
}

.logo {
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto;
    margin-bottom: 33px;
    margin-top: 12px;
}


#menuBody {
    height: 100vh;
    overflow-y: scroll;
    width: 130px;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;

    .menuItem {
        cursor: pointer;
        margin-bottom: 38px;

    }

    // .menuItem:nth-child(1) {
    //     padding-top: 90px;
    // }

    .menuIcon {
        width: 30px;
        height: 30px;
        object-fit: contain;
        display: block;
        margin: 0 auto;
    }

    .menuName {
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
        opacity: 0.6;
    }

    .menuNameActive {
        opacity: 1;
    }
}


.theme1 {
    background-color: #08596A;
}

.theme2 {
    background-color: #238296;
}
</style>
