<template>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" :close-on-click-modal="false" width="450px">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="原始密码：">
                <el-input v-model="form.name" placeholder="请输入原始密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码：">
                <el-input v-model="form.name" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：">
                <el-input v-model="form.name" placeholder="请再次输入确认密码"> </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer flex_center_end">
            <div class="btn1" @click="dialogVisible = false">取 消</div>
            <div class="btn2" @click="save">确 定</div>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                name: ""
            }
        }
    },
    methods: {
        show() {
            this.dialogVisible = true
        },
        save() {
            this.dialogVisible = false
        }
    }
}
</script>
<style scoped lang="less">
.btn1 {
    width: 90px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 24px;
    border: 1px solid #238296;
    font-size: 16px;
    color: #238296;
    line-height: 22px;
    text-align: center;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}

.btn2 {
    width: 90px;
    height: 40px;
    background: #238296;
    border-radius: 24px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    margin-left: 16px;
    cursor: pointer;
}
</style> 